import { FC } from 'react';
import Link from 'components/link/Link';
import { Grid } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Desktop } from 'modules/theme/components/responsive';

interface IItem {
    path: string;
    title: string;
}

export const Item: FC<IItem> = ({ path, title }) => {
    return <Link href={path}>
        <Grid container wrap='nowrap' spacing={1}>
            <Desktop>
                <Grid item width={80}/>
            </Desktop>

            <Grid item>
                <Svg icon component={MappinIcon} width={16} />
            </Grid>

            <Grid item>
                <Text variant='body2'>{title}</Text>
            </Grid>
        </Grid>
    </Link>;
};
