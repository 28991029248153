import { FC, ReactNode } from 'react';
import Link from 'components/link/Link';

interface IPathWrapper {
    url: string;
    children: ReactNode;
}

export const PathWrapper: FC<IPathWrapper> = ({ url, children }) => {
    return <Link href={url} sx={{
        '&:hover path.path-fill': {
            fill: '#44883E',
        },
        '&:hover path.stroke-fill': {
            strokeWidth: 2,
            stroke: '#fff',
            strokeLinejoin: 'round',
            '-webkit-filter': 'drop-shadow( 0px 0px 3px #c1c1c1)'
        },
        '&:hover path.region-name': {
            fill: 'white'
        }
    }}>
        {children}
    </Link>;
};
